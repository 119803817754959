<template>
  <div class="container-fluid p-0 d-block desktop-section" v-if="isDesktopSize">
    <div class="row flex-nowrap">
      <div class="col-auto col-md-5 col-xl-4 px-sm-2 px-0 top-round-container">
        <div
          class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white desktop_view"
        >
          <center style="align-self: center !important">
            <p class="text-center text_info">
              {{
                this.$i18n.locale === "en"
                  ? "Enter Your Number"
                  : "أدخل رقم هاتفك"
              }}
            </p>
            <!-- <div class="input">
                      <input type="text" placeholder="+964" class="game_input" v-model="phone" @input="valueChanged" />
                    </div> -->

            <div class="input-with-image mt-4">
              <input
                type="text"
                class="game_input input-btn"
                placeholder="+964"
                v-model="phone"
                @input="valueChanged"
              />
              <span class="input-icon"> </span>
            </div>

            <div class="mt-4 butn">
              <button
                :class="{ game_btn: true, activeBtn: isChanged }"
                id="sub_btn"
                :disabled="btnDisabled"
                @click="detectOperator()"
              >
                {{ btnDisabled ? "Loading ..." : "Send OTP" }}
              </button>
            </div>
          </center>
        </div>
      </div>
      <div class="col py-3">
        <!-- <b-img  fluid style="height:300px "  center src="../../assets/images/logo/logo.png"  alt="Center image"></b-img> -->
      </div>
    </div>
  </div>

  <div class="box" v-else>
    <div class="new fixed-bottom m-auto mobile-section">
      <p class="text-center text_info">
        {{
          this.$i18n.locale === "en" ? "Enter Your Number" : "أدخل رقم هاتفك"
        }}
      </p>
      <!-- <div class="input">
      <input type="text" placeholder="+964" class="game_input" v-model="phone" @input="valueChanged" />
    </div> -->

      <div class="input-with-image mt-4">
        <input
          type="text"
          class="game_input input-btn"
          placeholder="+964"
          v-model="phone"
          @keydown="handleKeyDown"
          @input="valueChanged"
        />
        <span class="input-icon"> </span>
      </div>

      <div class="mt-4 butn">
        <button
          v-if="this.$i18n.locale === 'en'"
          :class="{ game_btn: true, activeBtn: isChanged }"
          id="sub_btn"
          :disabled="btnDisabled"
          @click="detectOperator()"
        >
          {{ btnDisabled ? "Loading ..." : "Subscribe" }}
        </button>
        <button
          v-else
          :class="{ game_btn: true, activeBtn: isChanged }"
          id="sub_btn"
          :disabled="btnDisabled"
          @click="detectOperator()"
        >
          {{ btnDisabled ? "تحميل..." : "اشترك" }}
        </button>
      </div>

      <div
        style="direction: ltr !important"
        class="disclaimer"
        v-if="this.$i18n.locale === 'en'"
      >
        <p style="direction: ltr !important">
          Transform your Ramadan journey with access to the Quran, heartfelt
          supplications, Prophetic teachings, prayer timings, and exclusive
          content designed to inspire and guide you. Subscribe now!"
        </p>
        <p style="direction: ltr !important">
          For Zain subscribers, 240 IQD will be deducted daily for each received
          message. You can unsubscribe at any time by sending R1 to 3368.
        </p>
        <p style="direction: ltr !important">
          For Korek subscribers, the service will be free for 1 day then 300 IQD
          / day. You can unsubscribe at any time by sending 0 to 3805.
        </p>
        <p style="direction: ltr !important">
          To use this service, you must be over 18 years old or authorized to
          pay your phone bill.
        </p>
      </div>
      <div v-else class="disclaimer" style="direction: rtl !important">
        <p style="direction: rtl !important">
          قم بتغيير رحلتك الرمضانية من خلال الوصول إلى القرآن والأدعية الصادقة
          والتعاليم النبوية وأوقات الصلاة والمحتوى الحصري المصمم لإلهامك
          وإرشادك. اشترك الآن!
        </p>
        <p style="direction: rtl !important">
          لمشتركي زين، سيتم خصم 240 دينار عراقي يومياً عن كل رسالة مستلمة. يمكنك
          إلغاء الاشتراك في أي وقت عن طريق إرسال R1 إلى 3368.
        </p>
        <p style="direction: rtl !important">
          لمشتركي كورك، ستكون الخدمة مجانية لمدة يوم واحد ثم 300 دينار
          عراقي/يومياً. يمكنك إلغاء الاشتراك في أي وقت عن طريق إرسال 0 إلى 3805.
        </p>
        <p style="direction: rtl !important">
          لكي تستطيع إستخدام هذه الخدمة يجب عليك أن تكون فوق ال18 عام او مكفول
          لدفع الفاتورة.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AlertServie from "../../services/errors"
import ApiService from "../../services/api"
import { v4 as uuidv4 } from "uuid"

export default {
  data() {
    return {
      prefixPHone: "+964",
      phone: "+964",
      isChanged: false,
      btnDisabled: false,
      isDesktopSize: false,
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
  },
  watch: {
    phone(val) {
      if (val == "") {
        this.isChanged = false
      }
    },
  },
  methods: {
    checkScreenSize() {
      // Update isDesktopSize based on screen width
      this.isDesktopSize = window.innerWidth >= 768 // Adjust the breakpoint as needed
    },

    valueChanged(Val) {
      this.isChanged = true

      const countryCode = "+964"
      if (!this.phone.startsWith(countryCode)) {
        // If the input doesn't start with '+964', add it back
        this.phone = countryCode + this.phone.substring(countryCode.length)
      }
      if (this.phone.length >= 13) {
        this.phone = this.phone.slice(0, 14)
      }
    },

    handleKeyDown(val) {},

    // detect which operator is being used based on number prefix and call the appropriate function
    //we have zain and korek
    async detectOperator() {
      const phoneNumber = "964" + this.phone.replace(/^\+964/, "") // ✅ Fix: Only replace +964 if at the start
      if (phoneNumber.toString().startsWith("96478")) {
        this.$store.commit("setPhoneNumber", phoneNumber)
        await this.subscribeZain()
      } else {
        await this.generateScript()
      }
    },

    async generateScript() {
      this.btnDisabled = true
      var phoneNumber = "964" + this.phone.replaceAll("+964", "")
      if (phoneNumber.length < 7) {
        AlertServie.errorMessage("Invalid Phone")
        this.btnDisabled = false
        return
      }
      var uuid = "MyReligion-App" + uuidv4()
      var timestamp = this.getTimeStamp()
      this.$store.commit("setPhoneNumber", phoneNumber)
      this.$store.commit("setTi", uuid)
      this.$store.commit("setTS", timestamp)
      const response = await ApiService.generateScript(uuid, timestamp)
      if (response && response.status === 200) {
        var s = response.data.s
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.text = s
        document.head.appendChild(script)
        const ev = new Event("DCBProtectRun")
        document.dispatchEvent(ev)
        await this.sendSMS()
      } else {
        this.btnDisabled = false
        this.isLoading = false
      }
    },
    async sendSMS() {
      const phoneNumber = this.$store.state.phoneNumber
      const ti = this.$store.state.ti
      const response = await ApiService.login(phoneNumber, ti)
      this.isLoading = false
      if (response && response.status === 200) {
        this.$router.push("/verify")
      } else {
        this.btnDisabled = false
        this.errorMessage("Error")
      }
    },

    async subscribeZain() {
      const msisdn = this.$store.state.phoneNumber

      this.btnDisabled = true
      this.isLoading = true

      const response = await ApiService.loginZain(msisdn)

      if (response && response.status === 200) {
        // already subscribed user
        if (response.data?.code === 22) {
          ApiService.headers = {
            Authorization: "Bearer " + response.data.access_token,
            lang: localStorage.getItem("lang"),
          }
          localStorage.setItem("MyReligion", response.data?.data?.access_token)
          this.$router.push("/")
        } else {
          // Save referenceId for verification
          localStorage.setItem("referenceId", response.data?.data?.referenceId)
          this.$router.push("/verify")
        }
      } else {
        this.btnDisabled = false
        AlertServie.errorMessage(response.data.message)
      }
    },

    getTimeStamp() {
      return parseInt(new Date().getTime() / 1000)
    },
  },
  beforeDestroy() {
    // Clean up by removing the window resize event listener
    window.removeEventListener("resize", this.checkScreenSize)
  },
  mounted() {
    this.$i18n.locale = localStorage.getItem("lang") ?? "en"
    console.log(this.$i18n.locale)
    // Check screen size on component mount and update isDesktopSize
    this.checkScreenSize()
    // Listen for window resize events to update isDesktopSize
    window.addEventListener("resize", this.checkScreenSize)
  },
}
</script>

<style scoped>
.top-round-container {
  padding: 60px !important;
  background-color: #1c2e2f;
  border-top-right-radius: 6rem !important;
  border-bottom-right-radius: 6rem !important;
}

.disclaimer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  margin: 10px;
  color: #fff;
}

.new {
  padding: 45px !important;
  width: 100%;
  height: fit-content;
  background-color: #1c2e2f;
  margin: 20px;
  border-top-left-radius: 6rem !important;
  border-top-right-radius: 6rem !important;
}

.input-with-image {
  position: relative;
}

.input-with-image input[type="text"] {
  background: url("../../assets/images/textfield_background.png") no-repeat
    center;
  background-size: contain;
  /* padding: 10px 40px 10px 15px; */
  border: none;
  border-radius: 5px;
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 1;
  outline: none;
}

.input-btn {
  outline: none !important;
  /* padding: 30px !important; */
  color: #ffffff;
}

.input-btn::placeholder {
  color: #ffffff;
}

img {
  width: 348.75px;
  height: 181.16px;
}

@media (max-width: 768px) {
  img {
    width: 200px;
    height: 100px;
  }
}
</style>
